<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :persistent="loading"
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on: d }">
      <v-tooltip left>
        <span>Manage Courts/Times</span>
        <template v-slot:activator="{ on: tt }">
          <v-btn
            color="color3"
            class="mr-2"
            x-small text
            v-on="{ ...tt, ...d }"
          >
            Manage Courts/Times
          </v-btn>
        </template>
      </v-tooltip>
    </template>
    <v-card color="grey lighten-3">
      <div>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>{{name}} Court Assignment</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color1 color1Text--text" @click.stop="dialog = false" fab small :disabled="loading">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-tabs
        v-model="window"
        color="color3" centered
      >
        <v-tab>
          Courts
        </v-tab>
        <v-tab>
          Start Time
        </v-tab>
        <v-tab>
          Ref
        </v-tab>
      </v-tabs>
      </div>
      <v-window v-model="window">
        <v-window-item :key="0">
          <v-card-text class="px-1 py-0" :class="{'px-0': $vuetify.breakpoint.xsOnly}">
            <v-expand-transition>
              <v-container fluid v-if="eMatches">
                <v-row dense v-for="match in eMatches" :key="`match-${match.number}-c`">
                  <v-col cols="12">
                    <v-combobox
                      dense
                      v-model="match.court"
                      :search-input.sync="match.court"
                      :items="tournament.courts"
                      :label="`${matchNumberDisplay(match)} Court Assignment`"
                      persistent-hint
                      hint="Select a court or enter a new one"
                      color="color3"
                      item-color="color3"
                      clearable
                      outlined
                    ></v-combobox>
                  </v-col>
                </v-row>
              </v-container>
            </v-expand-transition>
          </v-card-text>
        </v-window-item>
        <v-window-item :key="1">
          <v-card-text class="px-1 py-0" :class="{'px-0': $vuetify.breakpoint.xsOnly}">
            <v-expand-transition>
              <v-container fluid v-if="eMatches">
                <v-row dense v-for="(match, i) in eMatches" :key="`match-${match.number}-st`">
                  <v-col cols="12">
                    <div>{{matchNumberDisplay(match)}} Start Time</div>
                    <date-time-picker
                      :datetime.sync="match.startTime"
                      label="Start"
                    ></date-time-picker>
                    <div v-if="i === 0" class="text-end" @click.stop="copyDown">
                      <v-btn color="color3" text x-small>copy down</v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-expand-transition>
          </v-card-text>
        </v-window-item>
        <v-window-item :key="2">
          <v-card-text class="px-1 py-0" :class="{'px-0': $vuetify.breakpoint.xsOnly}">
            <v-expand-transition>
              <v-container fluid v-if="eMatches">
                <v-row dense v-for="match in eMatches" :key="`match-${match.number}-r`">
                  <v-col cols="12">
                    <v-autocomplete
                      dense
                      :items="nonPoolTeams"
                      item-text="name"
                      return-object
                      v-model="match.manualRefTeam"
                      :label="`${matchNumberDisplay(match)} Ref Assignment`"
                      hint="Select a team to ref"
                      persistent-hint
                      color="color3"
                      item-color="color3"
                      clearable outlined
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-expand-transition>
          </v-card-text>
        </v-window-item>
      </v-window>
      <v-card-actions class="justify-end">
        <v-btn
          color="error white--text"
          small fab
          @click.stop="dialog = false"
          :disabled="loading"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
        <v-btn
          color="success white--text"
          small fab
          :disabled="!dirty"
          :loading="loading"
          @click.stop="save"
        >
          <v-icon>fas fa-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Match from '@/classes/Match'
import { mapGetters } from 'vuex'
import DateTimePicker from '@/components/Utils/DateTimePicker.vue'

export default {
  props: ['matches', 'name'],
  data () {
    return {
      dialog: false,
      eMatches: null,
      loading: false,
      window: 0
    }
  },
  computed: {
    ...mapGetters(['tournament', 'user']),
    ogCourts () {
      return this.matches && this.matches.map(m => m.court)
    },
    eCourts () {
      return this.eMatches && this.eMatches.map(m => m.court)
    },
    ogTimes () {
      return this.matches && this.matches.map(m => m.startTime)
    },
    eTimes () {
      return this.eMatches && this.eMatches.map(m => m.startTime)
    },
    ogRefs () {
      return this.matches && this.matches.map(m => m.manualRefTeam)
    },
    eRefs () {
      return this.eMatches && this.eMatches.map(m => m.manualRefTeam)
    },
    dirty () {
      return JSON.stringify(this.ogCourts) !== JSON.stringify(this.eCourts) ||
      JSON.stringify(this.ogTimes) !== JSON.stringify(this.eTimes) ||
      JSON.stringify(this.ogRefs) !== JSON.stringify(this.eRefs) ||
      (this.user && this.user.id === 1)
    },
    dto () {
      return this.eMatches.map(m => m.dto)
    },
    nonPoolTeams () {
      return this.tournament.activeTeams.filter(f => !this.idSet.has(f.id))
    },
    idSet () {
      const ids = new Set()
      this.match1.homeTeam && ids.add(this.match1.homeTeam.teamId)
      this.match1.awayTeam && ids.add(this.match1.awayTeam.teamId)
      return ids
    },
    match1 () {
      return this.eMatches && this.eMatches[0]
    }
  },
  methods: {
    edit () {
      this.eMatches = this.matches.map(m => new Match(this.$VBL, JSON.stringify(m), m.type))
    },
    matchNumberDisplay (m) {
      if (this.tournament.isLeague) return null
      if (m.title) return m.title
      const n = m.type === 'bracket' ? m.displayNumber : m.number
      return n ? `Match ${n}` : 'Bye'
    },
    save () {
      this.loading = true
      this.$VBL.post.matches(this.dto, false, false, false)
        .then(r => { this.$emit('match-saved') })
        .catch(err => console.log(err.response))
        .finally(() => {
          this.loading = false
          this.dialog = false
        })
    },
    copyDown () {
      const a = this.eMatches[0].startTime
      this.eMatches.forEach((m, i) => {
        m.startTime = a
      })
    }
  },
  watch: {
    dialog: function (val) {
      if (val) {
        this.edit()
      } else {
        this.window = 0
      }
    },
    'match1.startTime': function (val) {
      // for (let i = 1; i < this.eMatches.length; i++) {
      //   this.eMatches[i].startTime = val
      // }
    },
    'match1.manualRefTeam': function (val) {
      // for (let i = 1; i < this.eMatches.length; i++) {
      //   this.eMatches[i].manualRefTeam = val
      // }
    }
  },
  components: {
    DateTimePicker
  }
}
</script>
